import GetInTouch from "components/getInTouch";
import React from "react";
import MidUsefulLinks from "./mid";
import TopUsefulLinks from "./top";

const UsefulLinks = () => {
  return (
    <>
      <TopUsefulLinks />
      <MidUsefulLinks />
      <GetInTouch />
    </>
  );
};

export default UsefulLinks;
