import React from "react";

const TopUsefulLinks = () => {
  return (
    <div className="bg-qualityPolicy bg-cover bg-no-repeat h-[240px] sm:h-[300px]">
      <h1 className="text-[55px] sm:text-[65px] font-secondary font-semibold text-white pl-[22px] sm:pl-[138px] pt-[100px] sm:pt-[100px]">
        Useful Links
      </h1>
    </div>
  );
};

export default TopUsefulLinks;
