import React from "react";
import { Link } from "gatsby";

const MidUsefulLinks = () => {
  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#E8F1F5] pt-12">
      <div className="w-9/12 2xl:w-7/12 flex flex-col mx-auto justify-center pb-8">
        <h2 className="text-[33px] sm:text-[45px] font-semibold font-secondary">
          More <span className="text-orange">Resources</span>
        </h2>
        <div className="pl-[26px] sm:pt-[1rem] flex flex-wrap justify-between">
          <ul className="text-[20px] list-disc font-secondary text-[#00173A] font-medium flex flex-col flex-wrap gap-y-10 pt-[2.5rem]">
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/business-center.php">Blog For Customers</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/tomedes-insider.php">Tomedes Insights</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/translation-industry.php">Translation News</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/tools/language-detector">Language Detector</Link>
            </li>
          </ul>
          <ul className="text-[20px] list-disc font-secondary text-[#00173A] font-medium flex flex-col flex-wrap gap-y-10 pt-[2.5rem]">
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/translation-technology">Technology Tools</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/supported-file-formats">Supported File Formats</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/tools/text-summarizer">Summary Tool</Link>
            </li>
          </ul>
          <ul className="text-[20px] list-disc font-secondary text-[#00173A] font-medium flex flex-col flex-wrap gap-y-10 pt-[2.5rem]">
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/tools/word-count-ratio">Word Count Ratio</Link>{" "}
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/careers">Careers</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/crisis-communication-center">Coronavirus</Link>
            </li>
          </ul>
        </div>
        <h2 className="text-[33px] sm:text-[45px] font-semibold font-secondary pt-[7rem] sm:pt-[10rem]">
          More <span className="text-orange">Services</span>
        </h2>
        <div className="pl-[26px] sm:pt-[1rem] flex flex-wrap justify-between">
          <ul className="text-[18px] list-disc font-sans text-[#00173A] flex flex-col flex-wrap gap-y-10 pt-[2.5rem]">
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/localization-services.php">Localization Services</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/interpretation-services">Interpretation Services</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/literary-translation.php">Literary Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/foreclosure-translation.php">
                Foreclosure Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/manual-translation.php">Manual Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/business-card-translation.php">
                Business Card Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/product-catalog-translation.php">
                Product Catalog Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/blog-translation.php">Blog Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/diary-translation.php">Diary Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/poetry-translation.php">Poetry Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/prescription-translation.php">
                Prescription Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/thesis-translation.php">Thesis Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/environmental-translation.php">
                Environmental Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/swine-flu-translation.php">Swine Flu Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/human-resource-translation.php">
                Human Resource Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/website-translation/multilingual-seo-services/">
                Multilingual SEO
              </Link>
            </li>
          </ul>
          <ul className="text-[18px] list-disc font-sans text-[#00173A] flex flex-col flex-wrap gap-y-10 pt-[2.5rem] sm:w-[225px]">
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/travel-translation.php">Travel Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/twitter-translation.php">Twitter Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/presentation-translation.php">
                Presentation Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/ebay-translation.php">Ebay Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/visa-translation.php">Visa Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/automotive-translation.php">
                Automotive Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/banking-translation.php">Banking Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/iphone-applications-translation.php">
                Iphone Applications Translation
              </Link>{" "}
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/tattoo-translation.php">Tattoo Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/real-estate-translation.php">
                Real Estate Translation
              </Link>{" "}
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/professional-pr-translation.php">
                Professional PR Translation
              </Link>{" "}
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/subtitles-translation.php">Subtitles Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/market-reasearch-translation.php">
                Market Research Translation
              </Link>{" "}
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/insurance-document-translation.php">
                Insurance Document Translation
              </Link>{" "}
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/product-labels-translation.php">
                Product Labels Translation
              </Link>{" "}
            </li>
          </ul>
          <ul className="text-[18px] list-disc font-sans text-[#00173A] flex flex-col flex-wrap gap-y-10 pt-[2.5rem] sm:w-[265px]">
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/android-app-translation.php">Android App</Link>{" "}
              Translation
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/biography-translation.php">Biography Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/certificates-translation.php">
                Certificates Translation
              </Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/witness-statement-translation.php">
                Witness Statement Translation
              </Link>{" "}
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/human-translation.php">Human Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/urgent-translation.php">Urgent Translation</Link>
            </li>
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/flyer-leaflet-booklet-translation.php">
                Flyer Leaflet Booklet Translation
              </Link>{" "}
            </li>
            {/* <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/professional-translation.php">
                Professional Translation
              </Link>
            </li> */}
            <li className="hover:text-newOrange-100 transition-colors duration-300">
              <Link to="/transcript-translation-services">
                Transcript Translation Services
              </Link>{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full bg-GlobalContact bg-cover bg-no-repeat mx-auto py-7 px-8 md:px-12 text-white">
        <div className="2xl:w-9/12 flex justify-center mx-auto items-center gap-x-96 flex-wrap gap-y-12">
          <div className="text-[28px] flex flex-col">
            <span className="font-bold sm:text-left text-center">
              Contact Us 24/7
            </span>
            <p className="sm:text-left text-center">
              {" "}
              We will respond within 15 minutes
            </p>
          </div>
          <div className="">
            <Link
              to="/contactus.php"
              className="font-secondary font-semibold text-17 text-center uppercase rounded-full bg-[#000C27] text-white cursor-pointer  py-4 px-8 w-[253px] hover:bg-[#0032A7]"
            >
              Contact Us Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MidUsefulLinks;
