import Layout from "components/layout";
import UsefulLinks from "components/reusableComponents/usefulLinks/usefulLinks";
import SEO from "components/seo";
import React from "react";

const index = () => {
  const title = "Useful Links | Tomedes";
  const description = "Useful resources for translators and translation buyers";
  const keywords = "useful links";
  const slug = "/useful-links";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <UsefulLinks />
    </Layout>
  );
};

export default index;
